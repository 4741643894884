var disabledArr = [];
if (typeof disabledDays !== 'undefined') {
	disabledArr = disabledDays;
}
$(document).ready(function () {
	/**
	 * multisubmit protect
	 */
	$("#frm-confirm_order").submit(function () {
		$("#jsConfirmButton").attr("disabled", true);
	});

	$('#frm-supply_date').daterangepicker({
		minDate: getFirstAvailableDate(),
		maxDate: moment().add(6, 'days').format('YYYY-MM-DD'),
		singleDatePicker: true,
		"locale": {
			"separator": " do ",
			"applyLabel": "Zatwierdż",
			"cancelLabel": "Anuluj",
			"fromLabel": "Od",
			"toLabel": "Do",
			"startDate": null,
			"format": "YYYY-MM-DD",
			"daysOfWeek": [
				"Nd",
				"Pn",
				"Wt",
				"Śr",
				"Cz",
				"Pt",
				"Sb",
			],
			"monthNames": [
				"Styczeń",
				"Luty",
				"Marzec",
				"Kwiecień",
				"Maj",
				"Czerwiec",
				"Lipiec",
				"Sierpień",
				"Wrzesień",
				"Październik",
				"Listopad",
				"Grudzień"
			],
			"firstDay": 1
		},
		isInvalidDate: function (date) {
			if (!!(disabledArr.indexOf(date.format('YYYY-MM-DD')) > -1)) {
				return true;
			}

			if (date.day() === 0) {
				return true;
			}

			return false;
		}
	});

	$("#supply_date").on("change", "#frm-supply_date", function () {
		validateSaturday();
	});

	let invoiceField = $('#InvoiceJS');
	let invoiceData = $('#invoiceData');

	invoiceData.hide();

	if (invoiceField.prop('checked')) {
		invoiceData.show();
	}

	invoiceField.change(function () {
		if (this.checked) {
			invoiceData.show();
		} else {
			invoiceData.hide();
		}
	});

	validateSaturday();

});

function validateSaturday() {
	let supplyDateString = $('#frm-supply_date').val();
	let supplyDate = moment(supplyDateString);
	if (supplyDate.day() === 6) {
		$('#frm-supply_time').val(1);
		$('#frm-supply_time option[value="2"]').attr("disabled", true);
	} else {
		$('#frm-supply_time option[value="2"]').removeAttr("disabled", true);
	}
}

function getFirstAvailableDate() {
	let date = moment().add(1, 'days');
	let status = true;

	do {
		if (!!(disabledArr.indexOf(date.format('YYYY-MM-DD')) > -1) || date.day() === 0) {
			date = date.add(1, 'days');
			status = false;
		} else {
			status = true;
		}
	}
	while (!status);

	return date.format('YYYY-MM-DD');
}
